import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import Swiper from 'react-id-swiper/lib/custom';
import Icon from './Icon';
import s from './HomeCarousel.style';

class HomeCarousel extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.swiperItem = React.createRef();
  }

  goNext() {
    if (this.swiperItem) {
      this.swiperItem.current.swiper.slideNext();
    }
  }

  goPrev() {
    if (this.swiperItem) {
      this.swiperItem.current.swiper.slidePrev();
    }
  }

  render() {
    const params = {
      speed: 800,
      containerClass: css(s.carouselWrapper),
      wrapperClass: css(s.swiperWrapper),
      slideClass: css(s.slide),
      slidesPerView: 1,
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
    };

    return (
      <div className={ css(s.heroWrapper) }>
        <Swiper { ...params } ref={ this.swiperItem }>
          <div className={ css(s.slide) }>
            <div className={ css(s.logoWrapper) }>
              <Icon name="logo" className={ css(s.logo) } />
            </div>
            <img
              alt="The Makeup Lab"
              className={ css(s.slideBg) }
              src={ process.env.PUBLIC_URL + '/images/hero-new-1.jpg' }
            />
          </div>
          <div className={ css(s.slide) }>
            <img
              alt="Facechart"
              className={ css(s.slideBg) }
              src={ process.env.PUBLIC_URL + '/images/hero-new-3.jpg' }
            />
          </div>
          <div className={ css(s.slide) }>
            <img
              alt="Inspiracio"
              className={ css(s.slideBg, s.slideBgTop) }
              src={ process.env.PUBLIC_URL + '/images/hero-new-5.jpg' }
            />
          </div>
        </Swiper>
        <button
          className={ css(s.carouselButton, s.carouselButtonLeft) }
          onClick={ this.goPrev }>Prev
        </button>
        <button
          className={ css(s.carouselButton, s.carouselButtonRight) }
          onClick={ this.goNext }>Next
        </button>
      </div>
    );
  }
}

export default HomeCarousel;
