import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import Icon from './Icon';
import s from './Footer.style';

class Footer extends Component {
  state = {};

  render() {
    return (
      <footer className={ css(s.footer) }>
        <nav className={ css(s.footer__nav) }>
          <div className={ css(s.logoWrapper) }>
            <Icon name="logo" className={ css(s.logo) } />
            <p>The Makeup Lab<br />
              <a
                href="mailto:hello@themakeuplab.hu"
                className={ css(s.footerLink) }>hello@themakeuplab.hu</a><br />&copy; 2025
               / Minden jog fenntartva!
            </p>
          </div>
          <div className={ css(s.socialWrapper) }>
            <a
              href="https://www.tiktok.com/@themakeuplab_hu"
              className={ css(s.socialLink) }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="tiktok" className={ css(s.socialLogo) } />
            </a>
            <a
              href="https://www.linkedin.com/in/%C3%A1gnes-schm%C3%ADz-7998a587/"
              className={ css(s.socialLink) }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="linkedin" className={ css(s.socialLogo) } />
            </a>
            <a
              href="https://www.instagram.com/themakeuplab_hu/"
              className={ css(s.socialLink) }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="instagram" className={ css(s.socialLogo) } />
            </a>
            <a
              href="https://www.facebook.com/themakeuplabhu/"
              className={ css(s.socialLink) }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="facebook" className={ css(s.socialLogo) } />
            </a>
            <a
              href="https://www.youtube.com/channel/UCWKr7IebgUjkRAQmPOKWNcw/"
              className={ css(s.socialLink) }
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="youtube" className={ css(s.socialLogo) } />
            </a>
          </div>
        </nav>
      </footer>
    );
  }
}

export default Footer;
